/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Col, Container, Illustration, TitleHighlight } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Title = styled(TitleHighlight)(({ theme }) => ({
  margin: theme.spacing(0, 0, 10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    margin: theme.spacing(20, 0, 0),
  },
}));

const Wrapper = styled(Container)(({ theme }) => ({
  height: '160vw',
  position: 'relative',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    height: '50vw',
  },
}));

const WrapperImage = styled('div')(({ theme }) => ({
  '& .image': {
    position: 'absolute !important' as any,
  },

  '& .image:nth-child(1)': {
    '& .asset': {
      left: '-25%',
      top: '-25%',
      width: '50%',
    },
    display: 'none',
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      bottom: 0,
      display: 'block',
      left: 0,
      width: theme.spacing(63),
    },
  },

  '& .image:nth-child(2)': {
    '& .asset': {
      left: '40%',
      top: '-40%',
      width: '40%',
    },
    right: '1em',
    top: theme.spacing(40),
    width: `50%`,

    [theme.breakpoints.up(Breakpoints.Md)]: {
      right: 0,
      top: '15%',
      width: theme.spacing(84),
      zIndex: 2,
    },
  },

  '& .image:nth-child(3)': {
    '& .asset': {
      left: '38%',
      top: '5%',
      width: '30%',
    },
    left: '.5em',
    top: '40%',
    width: '50%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      left: 'auto',
      right: '18%',
      top: '26%',
      width: theme.spacing(84),
    },
  },

  '& .image:nth-child(4)': {
    '& .asset': {
      left: '-35%',
      top: '30%',
      width: '40%',
    },
    bottom: 0,
    right: '10%',
    width: '50%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      right: 0,
      width: theme.spacing(76),
    },
  },

  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
}));

const Hero: React.FC<HeroProps> = ({ content }) => {
  return (
    <Wrapper>
      <Col md={5}>
        <Title as="h1" variant="h4">
          {content.title}
        </Title>
      </Col>
      <WrapperImage>
        {content.images.map((item, index) => {
          return (
            <Illustration
              alt=""
              className="image"
              key={`image-${index}`}
              image={item.main.childImageSharp.gatsbyImageData}
              asset={item.asset?.childImageSharp.original.src}
              assetAspectRatio={
                item.asset
                  ? item.asset?.childImageSharp.original.width /
                    item.asset?.childImageSharp.original.height
                  : undefined
              }
            />
          );
        })}
      </WrapperImage>
    </Wrapper>
  );
};

interface ImageProps {
  publicURL: string;
  childImageSharp: { gatsbyImageData: IGatsbyImageData; original: IImage };
}

export interface HeroProps {
  content: {
    title: string;
    images: { main: ImageProps; asset: ImageProps }[];
  };
}

const query = graphql`
  fragment CareersHero on CareersYaml {
    hero {
      title
      images {
        main {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export { Hero, query };

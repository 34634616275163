/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import { Hero, HeroProps } from 'components/contents/Careers/Hero';
import {
  Jobs,
  JobsContent,
  JobsListProps,
} from 'components/contents/Careers/Jobs';
import Layout from 'components/partials/Layout';
import { LongList, LongListProps } from 'components/partials/LongList';

// Types

interface Props {
  data: {
    careersYaml: {
      metadata: MetaData;
      hero: HeroProps['content'];
      longList: LongListProps['content'];
      jobs: JobsContent;
    };
    allMarkdownRemark: {
      edges: JobsListProps[];
    };
  };
}

const Careers: React.FC<Props> = ({ data }) => {
  // Filter out jobs that are not visible
  const jobs = data.allMarkdownRemark.edges.filter(
    (j) => j.node.frontmatter.visible
  );
  return (
    <Layout
      title={data.careersYaml.metadata.title}
      description={data.careersYaml.metadata.description}
    >
      <Hero content={data.careersYaml.hero} />
      <LongList content={data.careersYaml.longList} />
      <Jobs content={data.careersYaml.jobs} jobList={jobs} />
    </Layout>
  );
};

export const query = graphql`
  query CareersQuery {
    careersYaml {
      metadata {
        title
        description
      }
      ...CareersHero
      ...CareersJob
      longList {
        title
        list {
          title
          text
          image {
            childImageSharp {
              gatsbyImageData
              original {
                height
                width
                src
              }
            }
          }
        }
      }
    }
    ...AllJobs
  }
`;

export default Careers;

/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { Col, Container, TitleHighlight, Wrapper } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const JobSection = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(16),
  marginTop: theme.spacing(10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
    marginTop: theme.spacing(30),
  },
}));

const JobSectionTitle = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'inline-block',
    fontSize: '1.7em',
    top: '-0.15em',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      top: '-0.05em',
    },
  },
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    marginTop: theme.spacing(3.25),
  },
}));

const NoJobsWrapper = styled('div')(({ theme }) => ({
  '& span': {
    display: 'inline-block',
    top: '-0.15em',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      top: '-0.05em',
    },
  },
  alignItems: 'center',
  display: 'flex',
  height: '100%',
}));

const JobWrapper = styled('a')(({ theme }) => ({
  '& svg': {
    '& path': {
      transition: theme.transitions.create('fill', {
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
  '&:hover': {
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    color: theme.palette.secondary.main,
  },
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3.25, 0),
  position: 'relative',
  textDecoration: 'none',
  transition: theme.transitions.create('color', {
    easing: theme.transitions.easing.easeInOut,
  }),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    padding: theme.spacing(3.25, 0),
  },
}));

const Jobs: React.FC<JobsProps> = ({ content, jobList }) => {
  return (
    <JobSection>
      <Col xs={12} md={5}>
        <JobSectionTitle as="h2" variant="h4">
          {content.title}
        </JobSectionTitle>
      </Col>
      <Col xs={12} md={7}>
        {jobList.length > 0 ? (
          jobList.map((job) => {
            // Renders content of documents at "content -> jobs"
            return (
              <JobWrapper
                key={job.node.frontmatter.title}
                href={job.node.frontmatter.path}
              >
                <Wrapper>
                  <Typography>
                    <b>{job.node.frontmatter.title}</b>
                  </Typography>
                  <Typography>{job.node.frontmatter.location}</Typography>
                </Wrapper>
              </JobWrapper>
            );
          })
        ) : (
          <NoJobsWrapper>
            <Typography>
              <i>{content.empty}</i>
            </Typography>
          </NoJobsWrapper>
        )}
      </Col>
    </JobSection>
  );
};

interface JobsProps {
  content: JobsContent;
  jobList: JobsListProps[];
}

export interface JobsContent {
  title: string;
  empty: string;
}

export interface JobsListProps {
  node: {
    frontmatter: {
      path: string;
      title: string;
      location: string;
      visible: boolean;
    };
  };
}

const query = graphql`
  fragment AllJobs on Query {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: "jobs" } } }) {
      edges {
        node {
          frontmatter {
            path
            title
            location
            category
            visible
          }
        }
      }
    }
  }
  fragment CareersJob on CareersYaml {
    jobs {
      empty
      title
    }
  }
`;

export { Jobs, query };
